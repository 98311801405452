import request from '@/utils/request'
import { getToken } from "@/utils/auth"


// 查询集运待签收列表
export function selectErpToBeSignedOrderList(query) {
  return request({
    url: '/tms/Order/selectErpToBeSignedOrderList',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: query
  })
}

// 查询VCB待签收列表
export function toBeSignedOrderVCBList(query) {
  return request({
    url: '/tms/Order/toBeSignedOrderVCBList',
    method: 'get',
    params: query
  })
}

// 查询订单签收，同单号支持多次签收列表
export function listOrderSign(query) {
  return request({
    url: '/tms/OrderSign/list',
    method: 'get',
    params: query
  })
}

// 查询订单签收，同单号支持多次签收详细
export function getOrderSign(orderSignId) {
  return request({
    url: '/tms/OrderSign/' + orderSignId,
    method: 'get'
  })
}

// 新增订单签收，同单号支持多次签收
export function addOrderSign(data) {
  return request({
    url: '/tms/OrderSign',
    method: 'post',
    data: data
  })
}

// 修改订单签收，同单号支持多次签收
export function updateOrderSign(data) {
  return request({
    url: '/tms/OrderSign',
    method: 'put',
    data: data
  })
}

// 删除订单签收，同单号支持多次签收
export function delOrderSign(orderSignId) {
  return request({
    url: '/tms/OrderSign/' + orderSignId,
    method: 'delete'
  })
}

/** ERP集运待签收订单打印 */
export function erpToBeSignedOrderPrint(expressId, langId) {
  return request({
    url: '/tms/Order/erpToBeSignedOrderPrint/' + expressId + '/' + langId,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get'
  })
}
