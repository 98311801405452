import { getRegionList } from "@/api/system/region";
/** tms 操作中心公共数据 moxlei 2022年6月22日10:35:37 */
export const tmsOperation ={
  data() {
    return {
      dataList: [],
      // 日期范围
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      // timeScope: [ this.parseTime(new Date(new Date().toLocaleDateString()).getTime()),this.parseTime(new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1)) ],
      timeScope: [],
      // 遮罩层
      loading: false,
      // 选中数组
      ids: [],
      labels: [],
      selectedData: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 表单参数
      form: {},
      // 表单校验
      rules: {}
    }
  },
  created() {
    //this.getList()
  },
  methods: {
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.form = {}
      this.resetForm('form')
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.timeScope = []
      this.resetForm('queryForm')
      this.handleQuery()
    },
    // 多选框选中数据
    handleSelection(selection) {
      this.ids = selection.map((item) => item.id)
      this.labels = selection.map((item) => item.id)
      this.selectedData = selection
      this.single = selection.length !== 1
      this.multiple = !selection.length
    }
  }
}
/**
 * 国家 moxlei 2022年6月22日10:35:48
 * */
export const sysRegion = {
  mounted() {
    this.getCountryList()
  },
  data(){
    return{
      countryList: [],
      countryMap: new Map()
    }
  },
  computed:{
    showContry(){
      return function(id){
        return this.countryMap.get(id) || id
      }
    }
  },
  methods:{
    getCountryList() {
      getRegionList(this.queryParams).then((response) => {
        this.countryList = response.rows;
      });
    },
  }
}
