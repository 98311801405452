<template>
  <div class="app-container">

    <el-row style="height: 100px;" :gutter="30">
      <el-col :span="30">
        <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="100px"
          label-position="top">
          <span class="mandatory1">*</span>
          <el-form-item  :label="td('快递单号')" prop="merge">
            <el-input class="down" style="width: 300px;" v-model="queryParams.expressId" :placeholder="td('请输入快递单号')" clearable ref="inputExpress"
              size="small" @keyup.enter.native="handleScanInput(1,'inputChannel')"></el-input>
          </el-form-item>
          <el-button style="margin-top: 40px;" type="primary" icon="el-icon-full-screen" size="mini" @click="handleScanInput(1,'inputChannel')">
            {{ td('扫描') }}
          </el-button>
          <br>
          <span class="mandatory2">*</span>
          <el-form-item class="dox" :label="td('运输方式')" prop="merge">
            <el-select class="down" style="width: 300px;" @change="channelChange('inputCusAddress')" v-model="queryParams.channel" ref="inputChannel"
              :placeholder="td('请选择运输方式')" clearable size="small"
              @keyup.enter.native="handleScanInput(3, 'inputChannel', queryParams.channel)">
              <el-option v-for="dict in tf('TRANSPORT_CHANNEL')" :key="dict.detailId" :label="dict.langTranslate"
                :value="dict.detailId" />
            </el-select>
          </el-form-item>
          <br />
          <span class="mandatory2">*</span>
          <el-form-item class="dox" :label="td('客户收货地址')" prop="merge">
            <el-select ref="inputCusAddress" class="down" style="width: 300px;" v-model="queryParams.cusAddress" @change="channelChange('inputReceAdd')"
                       :placeholder="td('请选择客户收货地址')" clearable size="small">
              <el-option v-for="dict in cusAddressList" :key="dict.cusAddressId" :label="dict.label"
                         :value="dict.cusAddressId" />
            </el-select>
          </el-form-item>
          <br />
          <span class="mandatory2">*</span>
          <el-form-item class="dox" :label="td('中国揽件仓库')" prop="merge">
            <el-select ref="inputReceAdd" class="down" style="width: 300px;" v-model="queryParams.receAdd" @change="channelChange('inputGoods')"
                       :placeholder="td('请选择中国揽件仓库')" clearable size="small">
              <el-option v-for="dict in receAddList" :key="dict.receAddressId" :label="dict.label"
                         :value="dict.receAddressId" />
            </el-select>
          </el-form-item>
          <br />
          <span class="mandatory2">*</span>
          <el-form-item class="dox" :label="td('货品名称')" prop="merge">
            <el-cascader size="small" class="down" style="width: 300px;" v-model="queryParams.goodsName"  :options="goodsTypeList"
              ref="inputGoods" :placeholder="td('请选择货品名称类')"
              @keyup.enter.native="handleScanInput(3, 'inputGoodsType', queryParams.goodsName)"
              @change="goodsIdChange">
            </el-cascader>
          </el-form-item>
          <br />
          <span class="mandatory2">*</span>
          <el-form-item class="dox" :label="td('商品品类')" prop="merge">
            <el-select :disabled="true" class="down" style="width: 300px;" @change="goodsTypeChange" v-model="queryParams.goodsType"
              ref="inputGoodsType" :placeholder="td('请选择商品品类')" clearable size="small"
              @keyup.enter.native="handleScanInput(3, 'inputPcs', queryParams.goodsType)">
              <el-option v-for="dict in tf('GOODS_TYPE')" :key="dict.detailId" :label="dict.langTranslate"
                :value="dict.detailId" />
            </el-select>
          </el-form-item>
          <br />
           <span class="mandatory2">*</span>
          <el-form-item class="dox" :label="td('商品数量')">
            <el-input class="down" style="width: 145px;" v-model="queryParams.pcs" :placeholder="td('箱数')" clearable ref="inputPcs" size="small"
              @keyup.enter.native="handleScanInput(3, 'inputWei', queryParams.pcs)">
            </el-input>
          </el-form-item>
           <span class="mandatory2">*</span>
          <el-form-item class="dox" :label="td('重量') + 'kg'">
            <el-input class="down" style="width: 145px;" v-model="queryParams.weight" :placeholder="td('重量')" clearable ref="inputWei" size="small"
              @keyup.enter.native="handleScanInput(3, 'inputLen', queryParams.weight)">
            </el-input>
          </el-form-item>
          <br />
          <span class="mandatory2">*</span>
          <el-form-item class="dox" style="width: 93px;" :label="td('长') + 'cm'">
            <el-input class="down" v-model="queryParams.vlength" :placeholder="td('长')" clearable ref="inputLen" size="small"
              @keyup.enter.native="handleScanInput(3, 'inputWid', queryParams.vlength)">
            </el-input>
          </el-form-item>
          <span class="mandatory2">*</span>
          <el-form-item class="dox" :label="td('宽') + 'cm'">
            <el-input class="down" style="width: 93px;" v-model="queryParams.vwidth" :placeholder="td('宽')" clearable ref="inputWid" size="small"
              @keyup.enter.native="handleScanInput(3, 'inputHei', queryParams.vwidth)">
            </el-input>
          </el-form-item>
          <span class="mandatory2">*</span>
          <el-form-item class="dox" :label="td('高') + 'cm'">
            <el-input class="down" style="width: 93px;" v-model="queryParams.vheight" :placeholder="td('高')" clearable ref="inputHei" size="small"
              @keyup.enter.native="handleSave">
            </el-input>
          </el-form-item>
          <el-form-item style="margin-top: -11px;">
            <el-button type="primary" icon="el-icon-tickets" size="mini" @click="handleSave">{{ td('提交') }}
            </el-button>
            <el-button icon="el-icon-refresh" size="mini" @click="resetQueryForm">{{ td('重置') }}
            </el-button>
            <el-button type="primary" icon="el-icon-search" size="mini" @click="uptPackSub(4)">{{ td('打印') }}
            </el-button>
            <el-button type="primary" icon="el-icon-search" size="mini" @click="copyData()">{{ td('复制') }}
            </el-button>
            <el-button type="primary" icon="el-icon-search" size="mini" @click="uptPackSub(3)">{{ td('保存') }}
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

    <el-table style="position: relative; top: -30px;" :data="tallyList"  @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="30px" align="center" />
      <el-table-column :label="td('序号')" type="index"  align="center">
        <template slot-scope="scope">
          <span>{{scope.$index + 1}}</span>
        </template>
      </el-table-column>
      <el-table-column  property="cuscode" :label="td('客户代号')"></el-table-column>
      <el-table-column property="expressId" :label="td('快递单号')"></el-table-column>
      <el-table-column  property="receiptId" :label="td('入库单号')"></el-table-column>
      <el-table-column property="goodsTypeT" :label="td('类别')">
        <template slot-scope="scope">
          <el-select v-model="scope.row.goodsTypeT">
            <el-option v-for="dict in tf('GOODS_TYPE')" :key="dict.detailId" :label="dict.langTranslate"
              :value="dict.detailId" />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column property="goodsName" :label="td('货品名称')" >
        <template slot-scope="scope">
          <el-cascader v-model="scope.row.goodsName"  :options="goodsTypeList" @change="goodsIdChange1(scope.row)">
          </el-cascader>
        </template>
      </el-table-column>
      <el-table-column property="length" :label="td('长') + 'cm'">
        <template slot-scope="scope">
          <el-input @change="calculateV(scope.row)" v-model="scope.row.length" ref="input_len"
            @keyup.enter.native="handleNext(scope.row.receiptId+'input_wid')"></el-input>
        </template>
      </el-table-column>
      <el-table-column property="width" :label="td('宽') + 'cm'" >
        <template slot-scope="scope">
          <el-input @change="calculateV(scope.row)" v-model="scope.row.width" :ref="scope.row.receiptId+'input_wid'"
            @keyup.enter.native="handleNext(scope.row.receiptId+'input_hei')"></el-input>
        </template>
      </el-table-column>
      <el-table-column property="height" :label="td('高') + 'cm'" >
        <template slot-scope="scope">
          <el-input @change="calculateV(scope.row)" v-model="scope.row.height" :ref="scope.row.receiptId+'input_hei'"
            @keyup.enter.native="handleNext(scope.row.receiptId+'input_wei')"></el-input>
        </template>
      </el-table-column>
      <el-table-column property="volume" :label="td('体积') + 'm³'" >
        <template slot-scope="scope">
          <el-input v-model="scope.row.volume"></el-input>
        </template>
      </el-table-column>
      <el-table-column property="weightS" :label="td('重量') + 'kg'" >
        <template slot-scope="scope">
          <el-input v-model="scope.row.weightS" :ref="scope.row.receiptId+'input_wei'"
            @keyup.enter.native="handlePrint()"></el-input>
        </template>
      </el-table-column>
      <el-table-column property="weightS" :label="td('包装')" >
        <template slot-scope="scope">
          <el-select v-model="scope.row.machiningType">
            <el-option v-for="dict in tf('MACHINING_TYPE')" :key="dict.detailId" :label="dict.langTranslate"
              :value="dict.detailId" />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column property="qtyInputQc" :label="td('商品数量')" width="90px">
        <template slot-scope="scope">
          <el-input v-model="scope.row.qtyInputQc"></el-input>
        </template>
      </el-table-column>
      <el-table-column :label="td('操作')" align="center" width="150" class-name="small-padding fixed-width"
        fixed="right">
        <template slot-scope="scope">
<!--          <el-button size="mini" type="text" icon="el-icon-more" @click="editPackSub(scope.row.receiptId)">-->
<!--          </el-button>-->
          <el-button type="primary" icon="el-icon-plus" plain circle @click="newPackSub(scope.row)"></el-button>
          <el-button type="primary" icon="el-icon-minus" plain circle @click="delPackSub(scope.row)"></el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--复制-->
    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="open_op" width="300px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-position="top" label-width="80px">
        <el-form-item :label="td('请输入复制数量')" prop="copyNum">
          <el-input v-model="form.copyNum" :placeholder="td('请输入复制数量')" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm_op">{{td("确定")}}</el-button>
        <el-button @click="cancel">{{td("取消")}}</el-button>
      </div>
    </el-dialog>

    <!-- 附加费录入 -->
    <el-dialog :close-on-click-modal="false" :title="td('附加费录入')" :visible.sync="dialogTableVisible0001" width="510px"
      append-to-body>
      <el-table v-loading="loading" :data="TmsPackSubFee">
        <el-table-column :label="td('费用类别')" prop="feeType" width="140">
          <template slot-scope="scope">
            <el-select :label="td('费用类别')" v-model="scope.row.feeType" :placeholder="td('费用类别')" clearable>
              <el-option v-for="dict in  tf('TMS_FEE_TYPE')" :key="dict.detailId" :label="dict.langTranslate"
                :value="dict.detailId" />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column :label="td('金额')" align="center" prop="fee" width="120">
          <template slot-scope="scope">
            <el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" clearable v-model="scope.row.fee"
              :placeholder="td('请输入')"></el-input>
          </template>
        </el-table-column>
        <el-table-column :label="td('备注')" align="center" prop="remark" width="120">
          <template slot-scope="scope">
            <el-input v-model="scope.row.remark" :placeholder="td('请输入')"></el-input>
          </template>
        </el-table-column>
        <el-table-column style="display:none" align="center" prop="packSubFeeId" width="1">
          <template slot-scope="scope">
            <el-input style="display:none" v-model="scope.row.packSubFeeId" :placeholder="td('请输入')"></el-input>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="orderCode" width="1">
          <template slot-scope="scope">
            <el-input style="display:none" v-model="scope.row.orderCode" :placeholder="td('请输入')"></el-input>
          </template>
        </el-table-column>
        <el-table-column :label="td('操作')" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" type="text" icon="el-icon-minus" @click="delTmsPackSubFee(scope.row)">
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <!--v-hasPermi="['tms:priceZy:add']"-->
        <el-button type="primary" plain @click="handleAdd()">{{td("新增")}} </el-button>
        <el-button type="primary" @click="submitaddTmsPackSubFeeForm">{{td("保存")}}</el-button>
        <el-button @click="submitaddTmsPackSubFeeFormClean">{{td("取消")}}</el-button>
      </div>
    </el-dialog>


    <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize"
      @pagination="getList" />

    <!-- 选货品名称时的弹窗 -->
    <el-dialog :close-on-click-modal="false" :destroy-on-close="true" :title="td('')" :visible.sync="openUp"
               width="400px">
      <div>
        <el-form :model="queryParams" ref="ExamineForm" style="">
          <el-row :gutter="20">
            <el-col :span="8" style="margin-top: -30px">
              <el-form-item :label="td('商品品类')" prop="salesman">
                <el-radio-group v-model="queryParams.goodsType" :placeholder="td('商品品类')" clearable style="width: 220px" size="small">
                  <el-radio v-for="dict in tf('GOODS_TYPE')" :key="dict.detailId" :label="dict.detailId">{{dict.langTranslate}}</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin-top: -20px; float: right;">
            <el-col :span="24">
              <el-button type="primary" @click="submit">{{ td('确定') }}</el-button>
              <el-button type="warning" @click="goodsCancel" style="margin-right: 4px;">{{ td('取消') }}</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-dialog>

  </div>
</template>

<script>
  import {
    newPackSub_copy,
    checkExpressId,
    checkCustomerId,
    erpSimpleInWarehouse,
    getGoodTypeAndName,
    getGoodstypeAndGoods,
    qcUptPackSub,
    allList,
    editPackSubList,
    delTmsPackSubFee,
    addandupdateTmsPackSubFee,
    newPackSub,
    delPackSub,
    qCScan,
    receAddList,
    listGoods,
    cusAddressList
  } from '@/api/tms/actionCenter'
  import { erpToBeSignedOrderPrint } from "@/api/tms/OrderSign";
  import { tmsOperation } from '@/api/tms/tmsMixin'
  import { getLodop } from '@/api/tms/LodopFuncs'
  import { td, tf } from '@/utils/bd'
  import ShangJi from '@/assets/voice/ShangJia.mp3'
  import ChuKu from '@/assets/voice/ChuKu.mp3'
  import Cookies from 'js-cookie'//hyl-11.5

  export default {
    name: 'SimpleIn',
    mixins: [tmsOperation],
    data() {
      return {
        // 货品名称集合
        goodsList: [],
        goodsMap: new Map(),
        // 弹窗
        openUp: false,
        // 中国收货地址集合
        receAddList: [],
        // 收货地址
        cusAddressList: [],
        // 客户代号
        cusData:[],
        qCList: [],
        title: null,
        open_op: false,
        ids: null,
        idsOp: null,
        tallyList: [],
        TmsPackSubFee: [],
        cXOrderCodeCX: null,
        dialogTableVisible0001: false,
        form: {
          copyNum: 1
        },
        // 表单校验
        rules: {
          copyNum: [{
            required: true,
            message: this.td("复制数量不能为空"),
            trigger: "blur"
          }],
        },
        // 货品名称集合
        goodsTypeList: [],
        /** 附加费录入form参数 */
        TmsPackSubFeeForm: {
          orderCode: null,
          feeType: null,
          fee: null,
          remark: null,
        },
        queryParams: {
          expressId: null,
          customerId: null,
          channel: null,
          receAdd: null,
          goodsId: null,
          goodsType: null,
          pcs: null,
          weight: null,
          vlength: null,
          vwidth: null,
          vheight: null,
		  channelChange: null,
		  cusAddress: null
        }
      }
    },
    created() {
      // 获取中国收货地址
      this.getReceAddList();
      // 获取收货地址
      this.getCusAddressList();
      getGoodstypeAndGoods().then(res => {
        for (let datum of res.data) {
          let option = {}
          option.value = datum.goodstypeId;
          option.label = datum.goodstypeC;
          option.children = [];
          for (let datumElement of datum.goodsList) {
            let element = {}
            element.value = datumElement.goodsId
            element.goodsType = datumElement.goodsType
            element.label = datumElement.goodsnameC
            option.children.push(element)
          }
          this.goodsTypeList.push(option)
        }
      })
      // 加载货品名称
      this.loadGoodsList()
    },
    methods: {
      /** 加载货品名称 */
      loadGoodsList() {
        let obj = { useObject: 'TMS'}
        listGoods(obj).then(res => {
          this.goodsList = res.rows
          this.goodsList.map(item => {
            this.goodsMap.set(item.goodsId, item.goodsname)
          })
        })
      },
      /** 获取中国收货地址 */
      getReceAddList() {
        // 获取国家id
        let langId = Cookies.get("langId")
        let obj = { langId: langId };
        receAddList(obj).then(res => {
          this.receAddList = res.rows;
          for (let receAdd of this.receAddList) {
            receAdd.label = receAdd.receName + '/' + receAdd.receTel + '/' + receAdd.receSiteName + '/' + receAdd.receAdd
          }
        })
      },
      /** 获取收货地址 */
      getCusAddressList() {
        cusAddressList().then(res => {
          this.cusAddressList = res.rows
          for (let ele of this.cusAddressList) {
            ele.label = ele.countryName + ele.provinceName + ele.cityName + ele.areaName + ele.address + '/' + ele.linkName + '/' + ele.tel;
            if (ele.isDef === 1) {
              this.queryParams.cusAddress = ele.cusAddressId
            }
          }
        })
      },
      // 级联框变化时调用
      goodsIdChange(value) {
        if (value) {
          this.queryParams.goodsId = value[1]
          // 给商品品类赋值
          for (let ele of this.goodsTypeList) {
            if (ele.value === value[0]) {
              for (let goods of ele.children) {
                if (goods.value === value[1]) {
                  this.queryParams.goodsType = goods.goodsType
                }
              }
            }
          }
          let that = this
          this.$nextTick(() => {
            that.$refs['inputGoodsType'].focus()
          })
        }
      },
      // 级联框变化时调用
      goodsIdChange1(value) {
        if (value) {
          value.goodsId = value.goodsName[1]
        }
      },
      /** 运输方式变化时回调 */
      channelChange(val) {
        this.$refs[val].focus()
      },
      goodsTypeChange() {
        this.$refs['inputPcs'].focus()
      },
      getList() {},
      /** 验证并聚焦 */
      handleScanInput(number, input, queryParams) {
        if (number === 1) {
          if (this.queryParams.expressId.length < 6) {
            return this.$message.warning(this.td('快递单号不能少于6位'))
          }
          checkExpressId(this.queryParams.expressId).then(res => {
            if (res.code === 200) {
              if (res.data === 1) {
                this.queryParams.expressId = null
                this.$refs['inputExpress'].focus()
                return this.$message.warning(this.td('此快递单号已经入库，请重新输入！'))
              } else if (res.data === 0) {
                this.$nextTick(() => {
                  this.$refs[input].focus()
                })
              }
            }
          })
        } else if (number === 2) {
          checkCustomerId(this.queryParams.customerId).then(res => {
            if (res.code === 200) {
              if (res.data === 0) {
                this.queryParams.customerId = null
                this.$refs['inputCustomer'].focus()
                return this.$message.warning(this.td('此客户代号不存在，请重新输入！'))
              } else if (res.data === 1) {
                this.$nextTick(() => {
                  this.$refs[input].focus()
                })
              }
            }
          })
        } else if (number === 3) {
          if (!this.queryParams.expressId) {
            queryParams = null
            return this.$message.warning(this.td('请先输入快递单号！'))
          }
          this.$nextTick(() => {
            this.$refs[input].focus()
          })
        }
      },
      /** 删除 */
      handleDelete(expressId) {
        for (let i = 0; i < this.tallyList.length; i++) {
          if (expressId === this.tallyList[i].expressId) {
            this.tallyList.splice(i, 1);
          }
        }
      },
      /** 提交 */
      handleSave() {
		if (!this.queryParams.expressId) {
			return this.$message.warning(this.td('请先输入快递单号！'))
		}
        if(this.queryParams.expressId.length <= 6){
			return this.$message.warning(this.td('快递单号不能少六位！'))
        }
        if(this.queryParams.channel==null || this.queryParams.channel==""){
			return this.$message.warning(this.td('请选择运输方式！'))
		}
		if(this.queryParams.receAdd==null || this.queryParams.receAdd==""){
			return this.$message.warning(this.td('请选择中国收货地址！'))
		}
		if(this.queryParams.goodsName==null || this.queryParams.goodsName==""){
			return this.$message.warning(this.td('请选择货品名称！'))

		}
		if(this.queryParams.goodsName==null || this.queryParams.pcs==""){
			return this.$message.warning(this.td('请输入商品数量！'))
		}
		if(this.queryParams.goodsName==null || this.queryParams.weight==""){
			return this.$message.warning(this.td('请输入重量！'))
		}
		if(this.queryParams.goodsName==null || this.queryParams.vlength==""){
			return this.$message.warning(this.td('请输入长度！'))
		}
		if(this.queryParams.goodsName==null || this.queryParams.vwidth==""){
			return this.$message.warning(this.td('请输入宽度！'))
		}
		if(this.queryParams.goodsName==null || this.queryParams.vheight==""){
			return this.$message.warning(this.td('请输入高度！'))
		}

        erpSimpleInWarehouse(this.queryParams).then(res => {
          if (res.code === 200) {
            if (!res.data.vMsg) {
              this.tallyList = res.data.ru
              for (let ele of this.tallyList) {
                ele.goodsName = []
                ele.goodsName.push(ele.goodstypeId * 1);
                ele.goodsName.push(ele.goodsId);
                if (ele.machiningType === '0') {
                  ele.machiningType = null
                }
              }
              this.resetQueryForm()
            } else {
              this.$message.warning(res.data.vMsg)
            }
          }
        })
      },
      /** 重置 */
      resetQueryForm() {
        this.queryParams = {
          expressId: null,
          customerId: null,
          channel: null,
          goodsId: null,
          goodsType: null,
          pcs: null,
          weight: null,
          vlength: null,
          vwidth: null,
          vheight: null
        }
      },
      /** 附加费新增按钮操作 */
      handleAdd() {
        let obj = new Object();
        obj.orderCode = this.cXOrderCodeCX;
        obj.id = new Date().valueOf() + "";
        this.TmsPackSubFee.push(obj)
      },
      /**
       * 新增
       */
      newPackSub(row) {
        row.goodsName = null
        const data = []
        let obj = {...row}
        data[0] = obj
        obj.copyNum = 1
        data[0].orderType = 3
        qcUptPackSub(data).then(re => {
          newPackSub_copy(obj).then(res => {
            this.queryParams.expressId = row.expressId;
            this.handleScan(this.queryParams)
          })
        })
      },
      /**
       * 删除
       * @param n
       */
      delPackSub(row) {
        row.goodsName = null
        delPackSub(row).then(res => {
          if (res.msg != '操作成功') {
            this.$message.error(this.td("删除失败:") + res.msg)
          }
          this.queryParams.expressId = row.expressId;
          this.handleScan(this.queryParams)
        })
      },
      /** 集包/保存/打印  */
      uptPackSub(n) {
        if (this.tallyList.length == 0) return
        for (let i = 0; i < this.tallyList.length; i++) {
          this.tallyList[i].orderType = n
          this.tallyList[i].goodsName = null
        }
        qcUptPackSub(this.tallyList).then((res) => {
          if (res.data.length > 0) {
            if (res.data[0].rsound === '1') {
              const audio = new Audio(ShangJi);
              audio.addEventListener('canplaythrough', () => {
                audio.play();
              });
            } else {
              const audio = new Audio(ChuKu);
              audio.addEventListener('canplaythrough', () => {
                audio.play();
              });
            }
          }
          if (n == 3) {
            let tmpList = []
            let flag = true
            for (const el of this.tallyList) {
              flag = true
              for (const v of this.qCList) {
                if (el.expressId == v.expressId) {
                  flag = false
                }
              }
              if (flag) {
                tmpList.push(el)
              }
            }
            this.qCList = this.qCList.concat(tmpList)
            this.queryParams.id = this.qCList[0].expressId + "";
            var mess;
            const dataw = tf('TMS_ORDER_TYPE')
            var dicts = new Map()
            for (const dataItem of dataw) {
              dicts.set(dataItem.detailId, dataItem.langTranslate)
            }
            allList(this.queryParams).then((response) => {
              mess = response.rows[0]
              for (const item of this.qCList) {
                item.soId = mess.soId
                item.createTime = mess.createTime
                item.expressCom = mess.expressCom
                item.countryId = mess.countryId
                item.orderTypeName = dicts.get(mess.orderType + "")
                item.orderType = mess.orderType
                item.createTime = mess.createTime
                item.qtyInputQc = mess.qtyInputQc
              }
              this.$message.success(this.td('保存成功'))
            })
          } else {
            if (this.tallyList.length === 0) {
              return this.$message.warning(this.td('请先录入订单号'))
            }

            //执行打印功能
            // console.log("执行打印功能")
            this.langId = Cookies.get("langId")
            erpToBeSignedOrderPrint(this.tallyList[0].expressId, this.langId).then(responses => {
              if (responses.code === 200) {
                // 执行打印功能
                this.TagPlan(responses.data);
              }
            })
          }
          this.tallyList = []
          this.$refs['inputExpress'].focus()
        })
      },

      /** 执行打印功能 */
      TagPlan(data) {
        let LODOP = getLodop()
        LODOP.PRINT_INIT("打印标签"); //调用getLodop获取LODOP对象
        LODOP.SET_PRINT_PAGESIZE(1, '105mm', '148mm', "");
        LODOP.SET_PRINT_STYLE("FontSize", 8);
        for (const it of data) {
          LODOP.NewPage()
          // LODOP.ADD_PRINT_BARCODE("1mm", "40mm", "46mm", "15mm", 'EAN128A', it.cuscode)
          LODOP.ADD_PRINT_BARCODE("17mm", "32mm", "55mm", "15mm", "EAN128A", it.expressId)
          let size = ''
          if (it.vlength && it.vwidth && it.vheight) {
            size = it.vlength + '×' + it.vwidth + '×' + it.vheight
          }
          it.boxNo = it.boxNo == null ? '' : it.boxNo
          it.countryId = it.countryId == null ? '' : it.countryId
          it.channel = it.channel == null ? '' : it.channel
          it.pcs = it.pcs == null ? '' : it.pcs
          it.weight = it.weight == null ? '' : it.weight
          let strHtml = "<table style=' border-collapse: collapse; ' border='1' Width='396' height='559' Cellspacing='0'>"+
                  "<tr>"+"<td Width='100' align='center'>"+this.td("快递单号")+"</td>"+"<td Width='220' align='center'>" + it.expressId + "</td>"+"<td>"+"</td>"+"</tr>"+
                  "<tr>"+"<td Width='100' align='center'>"+this.td("快递单号")+"</td>"+"<td Width='220'>"+"</td>"+"<td align='center'>"+it.boxNo+"</td>"+"</tr>"+
                  "<tr>"+"<td Width='100' align='center'>"+this.td("箱号")+"</td>"+"<td Width='220' align='center'>"+ it.boxNo +"</td>"+"<td>"+"</td>"+"</tr>"+
                  "<tr>"+"<td Width='100' align='center'>"+this.td("客户代号")+"</td>"+"<td Width='220' align='center'>"+it.cuscode+"</td>"+"<td>"+"</td>"+"</tr>"+
                  "<tr>"+"<td Width='100' align='center'>"+this.td("目的国")+"</td>"+"<td Width='220' align='center'>" + it.countryId +"</td>"+"<td>"+"</td>"+"</tr>"+
                  "<tr>"+"<td Width='100' height='63' align='center'>"+this.td("运输方式")+"<br/>"+"Mode of Shipment"+"</td>"+
                  "<td Width='186' align='center'>" + it.channel +"</td>"+"<td>"+"</td>"+"</tr>"+
                  "<tr>"+"<td Width='100' height='63' align='center'>"+this.td("产品数量")+"<br/>"+"Quantity(Pcs)"+"</td>"+"<td Width='220' align='center'>" + it.pcs +"</td>"+"<td>"+"</td>"+"</tr>"+
                  "<tr>"+"<td Width='100' height='63' align='center'>"+this.td("外箱尺寸")+"<br/>"+"Carton Size(L*W*H cm)"+"</td>"+"<td Width='220' align='center'>" + size +"</td>"+"<td>"+"</td>"+"</tr>"+
                  "<tr>"+"<td Width='100' height='63' align='center'>"+this.td("重量")+"<br/>"+"Weight(Kg)"+"</td>"+"<td Width='220' align='center'>" + it.weight+"</td>"+"<td>"+"</td>"+"</tr>"+
                  "</table>";
          LODOP.ADD_PRINT_HTM("0",'0',"100%","100%",strHtml);
        }
        LODOP.PREVIEW() // 弹框
      },
      // 多选框选中数据
      handleSelectionChange(selection) {
        // console.log(selection)
        this.ids = selection.map(item => item.id)
        this.idsOp = selection.map(item => item)
      },
      //复制
      copyData() {
        if (!this.idsOp || this.idsOp.length === 0) {
          return this.$message.warning(this.td('请先选择一条需要复制的数据！'))
        }
        this.open_op = true;
        //this.title = this.td("请输入复制数量");
      },
      /** 复制 */
      submitForm_op() {
        // 首先进行保存，再进行复制
        for (let i = 0; i < this.tallyList.length; i++) {
          this.tallyList[i].orderType = 3
          this.tallyList[i].goodsName = null
        }
        // 保存
        qcUptPackSub(this.tallyList).then((res) => {
          if (res.code === 200) {
            // 复制
            this.$refs["form"].validate(valid => {
              if (valid) {
                this.idsOp[0].copyNum = this.form.copyNum;
                // console.log(this.idsOp[0])
                newPackSub_copy(this.idsOp[0]).then(es => {
                  if (es != undefined && es != "undefined" && es.code == 200) {
                    this.$message.success(this.td("处理成功"));
                    this.open_op = false;
                    //处理复制立马显示效果
                    this.queryParams.expressId = this.idsOp[0].expressId;
                    this.handleScan(this.queryParams)
                    // qCScan(this.queryParams).then((response) => {
                    //   this.tallyList = response.data
                    // })
                  }
                })
              }
            })
          }
        })
      },
      cancel() {
        this.open_op = false;
      },
      /** 附加费录入 */
      editPackSub(receiptId) {
        editPackSubList(receiptId).then(res => {
          console.info(res);
          this.TmsPackSubFee = res.rows;
        })
        // this.TmsPackSubFeeForm.orderCode=receiptId;
        this.cXOrderCodeCX = receiptId;
        this.dialogTableVisible0001 = true;
      },
      /**
       * 附加费录入删除
       * @param row
       */
      delTmsPackSubFee(row) {
        if (row.id != null) {
          let id1 = this.TmsPackSubFee.findIndex(item => {
            if (item.id == row.id) {
              return true
            }
          })
          this.TmsPackSubFee.splice(id1, 1)
          return
        }
        delTmsPackSubFee(row.packSubFeeId).then(res => {
          if (res != undefined && res != 'undefined') {
            this.$message.success(this.td('删除成功'))
            //     this.editPackSub(this.cXOrderCodeCX);
            //  this.dialogTableVisible0001=false;
            if (row.packSubFeeId != null) {
              let id12 = this.TmsPackSubFee.findIndex(item => {
                if (item.packSubFeeId == row.packSubFeeId) {
                  return true
                }
              })
              this.TmsPackSubFee.splice(id12, 1)
              return
            }
          }
        })
      },
      /** 附加费录入保存按钮 */
      submitaddTmsPackSubFeeForm() {
        console.info(this.TmsPackSubFee);
        if (this.TmsPackSubFee.length == 0) {
          return;
        }
        let falgs = false;
        for (let i = 0; i < this.TmsPackSubFee.length; i++) {
          if (this.TmsPackSubFee[i].fee == null) {
            this.$message.info(this.td("金额不能为空！"));
            falgs = true;
            break;
          }
          if (this.TmsPackSubFee[i].feeType == null) {
            this.msgInfo(td("费用类别不能为空！"));
            falgs = true;
            break;
          }
        }
        if (falgs) {
          return;
        }
        //JSON.stringify(
        addandupdateTmsPackSubFee(this.TmsPackSubFee).then((response) => {
          if (response != undefined && response != 'undefined') {
            this.$message.success(this.td('保存成功'))
            this.dialogTableVisible0001 = false;
          }

        })
      },
      /** 附加费录入取消 */
      submitaddTmsPackSubFeeFormClean() {
        this.TmsPackSubFeeForm.orderCode = null;
        this.cXOrderCodeCX = null;
        this.dialogTableVisible0001 = false;
      },
      // tmsQC
      handleScan() {
        if (this.queryParams.expressId === null || this.queryParams.expressId === '') {
          return
        }
        this.queryParams.pageSize = null
        qCScan(this.queryParams).then((response) => {
          if (response.msg == 1) {
            this.vcbData = null
            this.tallyList = response.data.list
            this.volumeTotal = response.data.volumeTotal
            this.weightTotal = response.data.weightTotal
            this.qtyInputTotal = response.data.qtyInputTotal
            for (let ele of this.tallyList) {
              ele.goodsName = []
              ele.goodsName.push(ele.goodstypeId * 1);
              ele.goodsName.push(ele.goodsId);
              if (ele.machiningType === '0') {
                ele.machiningType = null
              }
            }
            // console.log("@@@@@@",this.tallyList[0].machiningType);
            this.dialogTableVisible = true
          } else {
            this.$message.warning(this.td('无主'))
            this.$refs.inputE.focus()
          }
          this.queryParams.expressId = null
        })
      },
      /** 计算体积 */
      calculateV(row) {
        row.volume = ((row.length * row.width * row.height) / 1000000).toFixed(4)
      },
      handleNext(input) {
        this.$nextTick(() => {
          this.$refs[input].focus()
        })
      },
      /** 商品品类弹窗确定按钮触发 */
      submit() {
        if (!this.queryParams.goodsType) {
          return this.$message.warning(this.td('请选选择商品品类'))
        }
        this.$refs['inputPcs'].focus();
        this.openUp = false
      },
      /** 商品品类弹窗取消 */
      goodsCancel() {
        this.openUp = false
      },
      /** 货品名称选择值触发 */
      goodsNameChange(val) {
        for (const ele of this.goodsTypeList) {
          if (ele.goodsId === val) {
            this.queryParams.goodsType = ele.goodsType
          }
        }
      }
    }
  }
</script>
<style scoped>
  /deep/ .mandatory1{
    color: red;
    position:relative;
    top: -30px;
  }
  /deep/.mandatory2{
    color: red;
    position:relative;
    top: -33px;
  }
  .down{
	  position: relative;
	  top: -16px;
  }
  .dox{
	  margin-top: -45px;
  }

</style>
<style>
  .cell {
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-all;
    line-height: 23px;
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
</style>
